import { SolutionCard } from "../SolutionCard";
import { solutions } from "../data/solutions";

export function OurSolutions() {
  return (
    <section className="flex justify-center items-center px-16 py-20 w-full bg-white max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col mb-6 w-full max-w-[1170px] max-md:max-w-full">
        <h2 className="text-4xl font-bold text-center leading-[52px] max-md:max-w-full text-brand-primary-2">
          Our <span className="">Solutions</span>
        </h2>
        <div className="mt-20 max-md:mt-10 max-md:max-w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            {solutions.map((solution) => (
              <SolutionCard
                imageSrc={solution.imageSrc}
                iconSrc={solution.iconSrc}
                title={solution.title}
                description={solution.description}
                ctaText="Find out more"
                ctaLink={solution.href}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
