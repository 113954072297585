import React, { useState } from "react";
import Button from "../Button";

interface IconInputProps {
  icon: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

const IconInput = ({ icon, placeholder, value, onChange }: IconInputProps) => {
  return (
    <div className="relative w-full mt-6">
      <input
        className="w-full p-4 pl-8 text-base text-gray-700 bg-white rounded-lg border border-solid border-zinc-200 focus:outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div
        className={`absolute inset-y-0  flex items-center pl-3 pointer-events-none ${
          icon === "%" ? "right-4" : "left-0"
        }`}
      >
        <span className="text-lg text-slate-500">{icon}</span>
      </div>
    </div>
  );
};

function formatUSD(amount: string) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseInt(amount, 10));
}

type PayoutResp = {
  savingsPct: number;
};

type PayinResp = {
  savingsPct: number;
};

export function Calculator() {
  const [tab, setTab] = useState<"payin" | "payout">("payout");
  const [payinCount, setPayinCount] = useState("");
  const [payinAmount, setPayinAmount] = useState("");
  const [payinInternationalPct, setPayinInternationalPct] = useState("");
  const [payinSubscriptionPct, setPayinSubscriptionPct] = useState("");

  const [payoutCount, setPayoutCount] = useState("");
  const [payoutAmount, setPayoutAmount] = useState("");
  const [payoutUsPct, setPayoutUsPct] = useState("");

  const [payoutSuccess, setPayoutSuccess] = useState<boolean | null>();
  const [payoutResp, setPayoutResp] = useState<PayoutResp | null>();

  const [payinSuccess, setPayinSuccess] = useState<boolean | null>();
  const [payinResp, setPayinResp] = useState<PayinResp | null>();

  const handlePayinSubmit = async () => {
    const payload = {
      mode: "payin",
      paymentsCount: parseInt(payinCount),
      paymentsAvgAmount: parseFloat(payinAmount),
      usPctWhole: 100 - parseInt(payinInternationalPct),
      subscriptionPctWhole: parseInt(payinSubscriptionPct),
    };

    try {
      // setPayinSuccess(true);
      // setPayinResp({
      //   savingsPct: 80,
      // });

      const response = await fetch("/api/pricing-calculator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      setPayinSuccess(true);
      setPayinResp(data);
      console.log("Payin submission successful:", data);
      // Handle the response as needed
    } catch (error) {
      setPayinSuccess(false);
      console.error("Error submitting payin form:", error);
      // Handle the error as needed
    }
  };

  const handlePayoutSubmit = async () => {
    const payload = {
      mode: "payout",
      paymentsCount: parseInt(payoutCount),
      usPctWhole: parseInt(payoutUsPct),
    };

    try {
      // setPayoutSuccess(true);
      // setPayoutResp({
      //   savingsPct: 80,
      // });
      const response = await fetch("/api/pricing-calculator", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log("Payout submission successful:", data);
      setPayoutSuccess(true);
      setPayoutResp(data);
      // Handle the response as needed
    } catch (error) {
      // setPayoutSuccess(false);
      console.error("Error submitting payout form:", error);
      // Handle the error as needed
    }
  };

  if (payoutSuccess && payoutResp) {
    return (
      <PayoutSuccess
        onClickEdit={() => {
          setPayoutResp(null);
          setPayoutSuccess(null);
        }}
        savingsPercent={payoutResp?.savingsPct}
        monthlyPayees={payoutCount}
        monthlyPayouts={payoutAmount}
        usPayoutPercent={payoutUsPct}
      />
    );
  }

  if (payinSuccess && payinResp) {
    return (
      <PayinSuccess
        onClickEdit={() => {
          setPayinResp(null);
          setPayinSuccess(null);
        }}
        savingsPercent={payinResp?.savingsPct}
        monthlyPayinCount={payinCount}
        monthlyPayinAmount={payinAmount}
        internationalCount={payinInternationalPct}
        subscriptionCount={payinSubscriptionPct}
      />
    );
  }

  return (
    <div className="flex overflow-hidden flex-col">
      <div className="w-full text-4xl font-bold leading-tight text-center text-slate-800 max-md:max-w-full">
        <span className="text-slate-800">Ready to Transform </span>
        <span className="text-slate-800">Your Payment Process?</span>
      </div>
      <div className="mt-12 text-lg text-center text-slate-500 max-md:mt-10 max-md:max-w-full">
        Start Saving! It's fast and simple to start saving on your payments.
      </div>
      <div className="mt-8 bg-violet-50 p-8 rounded-2xl">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="bg-white p-8 rounded-2xl">
            <div className="flex flex-col lg:flex-row gap-6 items-center w-full text-lg text-center text-black bg-white rounded-lg max-lg:max-w-full">
              <button
                onClick={() => setTab("payout")}
                className={`flex-1 shrink gap-2 self-stretch py-2 my-auto rounded-md border-2 border-solid ${
                  tab === "payout" ? "border-brand-primary-1" : ""
                }`}
              >
                Payout
              </button>
              <button
                onClick={() => setTab("payin")}
                className={`flex-1 shrink gap-2 self-stretch py-2 my-auto rounded-md border-2 border-solid ${
                  tab === "payin" ? "border-brand-primary-1" : ""
                }`}
              >
                Payin (Card Processing)
              </button>
            </div>

            {tab === "payout" ? (
              <>
                <div className="flex flex-col mt-12 w-full text-base max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      How many payees do{" "}
                      <span className="text-slate-800">
                        you send payouts to each month?
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 text-slate-500 max-md:max-w-full">
                    This is the total number of payees you pay monthly.
                  </div>
                  <IconInput
                    icon=""
                    placeholder="0"
                    value={payoutCount}
                    onChange={setPayoutCount}
                  />
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      How much do you{" "}
                      <span className="text-slate-800">pay out monthly?</span>
                    </div>
                  </div>
                  <div className="mt-6 text-base text-slate-500 max-md:max-w-full">
                    This is the total amount of money you pay out monthly.
                  </div>
                  <IconInput
                    icon="$"
                    placeholder="0.00"
                    value={payoutAmount}
                    onChange={setPayoutAmount}
                  />
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      What percentage of your payouts are{" "}
                      <span className="text-slate-800">
                        sent to payees in the USA?
                      </span>
                    </div>
                  </div>
                  <div className="mt-6 text-base text-slate-500 max-md:max-w-full">
                    This is the percentage of pay outs that are made to US
                    payees.
                  </div>
                  <IconInput
                    icon="%"
                    placeholder="0"
                    value={payoutUsPct}
                    onChange={setPayoutUsPct}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col mt-12 w-full text-base max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      How many transactions do you process in a month on
                      average?
                    </div>
                  </div>
                  <IconInput
                    icon=""
                    placeholder="0"
                    value={payinCount}
                    onChange={setPayinCount}
                  />
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      What's the total dollar amount of your average monthly
                      transactions?
                    </div>
                  </div>
                  <IconInput
                    icon="$"
                    placeholder="0.00"
                    value={payinAmount}
                    onChange={setPayinAmount}
                  />
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      What percentage of your transactions are international?
                    </div>
                  </div>
                  <IconInput
                    icon="%"
                    placeholder="0"
                    value={payinInternationalPct}
                    onChange={setPayinInternationalPct}
                  />
                </div>
                <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
                  <div className="flex flex-wrap gap-4 items-center w-full text-2xl leading-snug text-slate-800 max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/76788ba9dc0da6ec9161cd75e89e18f9789c8ec14f69d9f4e96226a867e11adf?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                      className="object-contain shrink-0 self-stretch my-auto aspect-[0.95] w-[19px]"
                    />
                    <div className="flex-1 shrink self-stretch my-auto basis-0 min-w-[200px] max-md:max-w-full">
                      What percentage of your transactions are recurring (e.g.
                      subscriptions)?
                    </div>
                  </div>
                  <IconInput
                    icon="%"
                    placeholder="0"
                    value={payinSubscriptionPct}
                    onChange={setPayinSubscriptionPct}
                  />
                </div>
              </>
            )}

            <Button
              text="See Savings"
              variant="primary"
              className="self-center mt-12 min-h-[50px] max-md:mt-10"
              onClick={
                tab === "payin"
                  ? () => handlePayinSubmit()
                  : () => handlePayoutSubmit()
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function SuccessCTA() {
  return (
    <div className="flex flex-col mt-12 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col justify-center p-16 w-full bg-violet-50 rounded-2xl max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="text-4xl font-bold leading-tight text-center max-md:max-w-full">
            Time is money. <span className="text-blue-600">Save Both.</span>
          </div>
          <a className="flex justify-center" href="/request-demo">
            <Button
              text="Request a Demo Now"
              variant="primary"
              className="self-center mt-10 min-h-[50px]"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

function PayinSuccess({
  savingsPercent,
  monthlyPayinAmount,
  monthlyPayinCount,
  internationalCount,
  subscriptionCount,
  onClickEdit,
}: {
  savingsPercent: number;
  monthlyPayinAmount: string;
  monthlyPayinCount: string;
  internationalCount: string;
  subscriptionCount: string;
  onClickEdit: () => void;
}) {
  return (
    <div>
      <div className="flex justify-center">
        <h2 className="text-brand-primary-2 text-4xl font-bold">
          See How Much Payment Labs Can Save You
        </h2>
      </div>

      <div className="flex flex-wrap gap-8 justify-center items-center p-8 mt-12 w-full bg-violet-50 rounded-2xl max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-8 justify-center items-center p-8 mt-12 w-full bg-violet-50 rounded-2xl max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col flex-1 shrink justify-center self-stretch p-8 my-auto bg-white rounded-2xl basis-0 min-w-[320px] max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col md:flex-row md:flex-wrap gap-2 items-start mt-6 w-full text-center max-md:max-w-full">
              <div className="flex gap-10 items-start w-full text-lg text-gray-700 max-md:max-w-full">
                <div className="flex-1 shrink w-full min-w-[240px] max-md:max-w-full">
                  The above savings is based on your entries:
                </div>
              </div>
              <div className="flex flex-col mt-6 w-full text-center max-md:max-w-full">
                <div className="flex flex-wrap gap-2 items-start w-full max-md:max-w-full">
                  <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                    <div className="text-xs text-gray-700">
                      Monthly Transactions
                    </div>
                    <div className="mt-4 text-lg font-bold text-blue-600">
                      {monthlyPayinCount}
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                    <div className="text-xs text-gray-700">
                      Monthly Transactions Amount:
                    </div>
                    <div className="mt-4 text-lg font-bold text-blue-600">
                      {formatUSD(monthlyPayinAmount)}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2 items-start mt-2 w-full max-md:max-w-full">
                  <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                    <div className="text-xs text-gray-700">
                      % of International Transactions
                    </div>
                    <div className="mt-4 text-lg font-bold text-blue-600">
                      {internationalCount}%
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                    <div className="text-xs text-gray-700">
                      % of Recurring Transactions
                    </div>
                    <div className="mt-4 text-lg font-bold text-blue-600">
                      {subscriptionCount}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center self-center mt-10 text-base font-bold text-blue-600">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d420fe0609ff719d8424a6efc4935567b667505bd4ab30c19695521836c9c4be?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
              />
              <button className="self-stretch my-auto" onClick={onClickEdit}>
                Edit Values
              </button>
            </div>
          </div>
          <div className="flex flex-col flex-1 shrink justify-center self-stretch px-8 py-28 min-h-full font-bold bg-white rounded-2xl basis-0 min-h-[341px] min-w-[320px] max-md:px-5 max-md:py-24 max-md:max-w-full">
            <div className="text-2xl leading-snug text-center text-slate-800 max-md:max-w-full">
              With Payment Labs you could save:
            </div>
            <div className="flex gap-2 items-center self-center px-10 py-6 mt-10 text-3xl text-blue-600 whitespace-nowrap border-2 border-blue-600 border-dashed bg-blue-600 bg-opacity-10 rounded-[12100px] max-md:px-5">
              <div className="self-stretch my-auto">{savingsPercent}</div>
              <div className="self-stretch my-auto">%</div>
            </div>
          </div>
        </div>
        <SuccessCTA />
      </div>
    </div>
  );
}

function PayoutSuccess({
  savingsPercent,
  monthlyPayees,
  monthlyPayouts,
  usPayoutPercent,
  onClickEdit,
}: {
  savingsPercent: number;
  monthlyPayees: string;
  monthlyPayouts: string;
  usPayoutPercent: string;
  onClickEdit: () => void;
}) {
  return (
    <div>
      <div className="flex justify-center">
        <h2 className="text-brand-primary-2 text-4xl font-bold">
          See How Much Payment Labs Can Save You
        </h2>
      </div>
      <div className="flex flex-wrap gap-8 justify-center items-center p-8 mt-12 w-full bg-violet-50 rounded-2xl max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink justify-center self-stretch p-8 my-auto bg-white rounded-2xl basis-0 min-w-[320px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <div className="flex gap-10 items-start w-full text-lg text-gray-700 max-md:max-w-full">
              <div className="flex-1 shrink w-full min-w-[240px] max-md:max-w-full">
                The above savings is based on your entries:
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-2 items-start mt-6 w-full text-center max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                <div className="text-xs text-gray-700">Monthly Payees</div>
                <div className="mt-4 text-lg font-bold text-blue-600">
                  {monthlyPayees}
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                <div className="text-xs text-gray-700">Monthly Payouts:</div>
                <div className="mt-4 text-lg font-bold text-blue-600">
                  {formatUSD(monthlyPayouts)}
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink px-4 py-6 bg-violet-50 rounded-md basis-0 min-w-[150px] w-full">
                <div className="text-xs text-gray-700">% of US Payouts</div>
                <div className="mt-4 text-lg font-bold text-blue-600">
                  {usPayoutPercent}%
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2 items-center self-center mt-10 text-base font-bold text-blue-600">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/67eea8e79263c648efb211eea065185665820fc7dc7031bba170ecba42d8e7c4?placeholderIfAbsent=true&apiKey=c3b06b05ed9c4953aa7529cc8ba858eb"
              className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
            />
            <button className="self-stretch my-auto" onClick={onClickEdit}>
              Edit Values
            </button>
          </div>
        </div>
        <div className="flex flex-col flex-1 shrink justify-center self-stretch px-8 py-14 min-h-full font-bold bg-white rounded-2xl basis-0 min-h-[247px] min-w-[320px] max-md:px-5 max-md:max-w-full">
          <div className="text-2xl leading-snug text-center text-slate-800 max-md:max-w-full">
            <span className="text-slate-800">
              With Payment Labs you could save:
            </span>
          </div>
          <div className="flex gap-2 items-center self-center px-10 py-6 mt-10 text-3xl text-blue-600 whitespace-nowrap border-2 border-blue-600 border-dashed bg-blue-600 bg-opacity-10 rounded-[12100px] max-md:px-5">
            <div className="self-stretch my-auto">{savingsPercent}%</div>
          </div>
        </div>
      </div>
      <SuccessCTA />
    </div>
  );
}
