import React from "react";

export type SolutionCardProps = {
  imageSrc: string;
  iconSrc: React.ReactNode | string;
  title: string;
  description: string;
  ctaText: string;
  ctaLink: string;
};

export const SolutionCard: React.FC<SolutionCardProps> = ({
  imageSrc,
  iconSrc,
  title,
  description,
  ctaText,
  ctaLink,
}) => (
  <article className="flex flex-end justify-end overflow-hidden relative flex-col grow  p-6 rounded-2xl min-h-[400px] max-md:px-5 max-md:mt-8 max-md:max-w-full">
    <img
      loading="lazy"
      src={imageSrc}
      alt=""
      className="object-cover absolute inset-0 size-full"
    />
    <div className="flex relative flex-col justify-between p-6 mt-32 rounded-lg bg-white bg-opacity-90 max-md:px-5 max-md:mt-10 max-md:max-w-full min-h-[200px]">
      <div className="flex flex-col max-md:max-w-full">
        <h3 className="flex gap-2 text-3xl leading-9 whitespace-nowrap text-slate-800 max-md:flex-wrap">
          <span className="self-center">
            {typeof iconSrc === "string" ? (
              <img className="h-[36px]" src={iconSrc} />
            ) : (
              <>{iconSrc}</>
            )}
          </span>
          <span className="flex-1 my-auto max-md:max-w-full">{title}</span>
        </h3>
        <p className="mt-5 text-base leading-6 text-zinc-800 max-md:max-w-full overflow-hidden line-clamp-3">
          {description}
        </p>
      </div>
      <a
        href={ctaLink}
        className="flex gap-2 pr-20 mt-6 text-base font-bold leading-6 text-blue-600 max-md:flex-wrap max-md:pr-5"
      >
        <span className="my-auto">{ctaText}</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <rect width="24" height="24" fill="#434956" />
          </mask>
          <g>
            <path
              d="M14 18L12.6 16.55L16.15 13H4V11H16.15L12.6 7.45L14 6L20 12L14 18Z"
              fill="#226CFF"
            />
          </g>
        </svg>
      </a>
    </div>
  </article>
);
